<template>
  <vs-table v-if="cityData.open" :data="cityData.data">
    <template slot="thead">
      <vs-th>Spoc Name</vs-th>
      <vs-th>MWB Count</vs-th>
      <vs-th>Coverage</vs-th>
      <vs-th>Enrollment</vs-th>
      <!-- <vs-th>M7 Count</vs-th> -->
      <vs-th>Visitors</vs-th>
      <vs-th>Utilization</vs-th>
      <vs-th>Delays</vs-th>
      <vs-th>Dues</vs-th>
      <vs-th>SR%</vs-th>
    </template>
    <template slot-scope="{ data }">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data">
        <vs-td :data="data[indextr].email">
          <a v-on:click="spoc_name">{{ data[indextr].spoc_name }}</a>
        </vs-td>
        <vs-td :data="data[indextr].username">{{
          data[indextr].mwb_count
        }}</vs-td>
        <vs-td :data="data[indextr].username"
          >{{ data[indextr].coverage }}%</vs-td
        >
        <vs-td :data="data[indextr].id">{{
          data[indextr].actual_enrollments
        }}</vs-td>

        <!-- <vs-td :data="data[indextr].id">{{
          data[indextr].actual_enrollments
        }}</vs-td> -->

        <vs-td :data="data[indextr].id">{{
          data[indextr].visitors.length
        }}</vs-td>
        <vs-td :data="data[indextr].id">{{ data[indextr].utilization }}%</vs-td>
        <vs-td :data="data[indextr].id">{{ data[indextr].delays }}</vs-td>
        <vs-td :data="data[indextr].id">{{ data[indextr].dues }}</vs-td>
        <vs-td :data="data[indextr].id">{{ data[indextr].sr }}</vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
export default {
  props: {
    cityData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.cityData);
  },
  methods: {
    spoc_name() {
      this.$router.push("SingleBdeStats");
    },
  },
};
</script>

<style></style>
